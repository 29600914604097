


a {
	text-decoration: none;
}
img {
	max-width: 100%;
}
nav {
	text-decoration: none;
}

.container .btn:hover {
	background: #0097e6;
}

.error-page {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 20px;
	height: 60vh;
}

.error-page .error-code {
	font-size: 150px;
	font-weight: 600;
	color: #00a8ff;
}

.error-page .error-text {
	font-size: 34px;
	font-weight: 600;
	color: #000;
}

#app {
  height: 100%;
}
html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


